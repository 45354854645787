.contact {
  display: flex;
  width: 100%;
  padding: 10% 0;
  .contact__title {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .header {
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: 0.2rem;
      color: #fefefe;
      margin-top: 0 !important;
      margin-bottom: 10px !important;
      text-transform: uppercase;
    }
    .subtitle {
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
      color: #fefefe;
      margin-top: 0 !important;
    }
    .highlight {
      color: #fefefe;
      text-decoration: none;
      font-size: 1.2rem;
      width: 20%;
      transition: all 0.3s ease-in-out;
      margin: 0 auto;
      padding: 15px 40px;
      border: 1px solid #fefefe;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background-color: transparent;
      &:hover {
        background-color: #fefefe;
        color: #000;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .contact {
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px 40px 20px;
    .contact__title {
      width: 100%;
      .header {
        font-size: 2rem;
      }
      .subtitle {
        font-size: 1.2rem;
      }
      .highlight {
        font-size: 1rem;
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .contact {
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px 40px 20px;
    .contact__title {
      .header {
        font-size: 2rem;
      }
      .subtitle {
        font-size: 1.2rem;
      }
      .highlight {
        font-size: 1rem;
        width: 50%;
      }
    }
  }
}

@media screen and (min-width: 1024px) and (max-width: 1366px) {
  .contact {
    flex-direction: column;
    gap: 20px;
    padding: 20px 20px 0 20px;
    .contact__title {
      .header {
        font-size: 2rem;
      }
      .subtitle {
        font-size: 1.2rem;
      }
      .highlight {
        font-size: 1rem;
        width: 25%;
      }
    }
  }
}
