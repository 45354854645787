@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@700&display=swap');

.navbar {
  border-bottom: 1px solid #514f4f;
  background-color: rgb(48, 41, 41);
  padding: 3rem 1rem 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
  .navbar__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 65%;
    margin: 0 auto;
    transition: all 0.5s ease-in-out;
    ul {
      display: flex;
      justify-content: space-around;
      width: 100%;
      list-style: none;
      li {
        margin: 0 0.5rem;
        // font-family: 'Kanit', sans-serif;
        font-family: 'Tahoma';
        text-transform: uppercase;
        font-size: 1.3rem;
        font-weight: 500;
        letter-spacing: 0.2rem;
        text-decoration: none;
        color: #fefefe;
        letter-spacing: 0.1rem;
        font-weight: 500;
        transition: all 0.5s ease-in-out;
        cursor: pointer;
        &:hover {
          color: #c07a6e;
        }
      }
    }
  }
}
.navbar__container__scrolled {
  padding-top: 20px;
  transition: all 0.5s ease;
  .navbar__menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    transition: all 0.5s ease-in-out;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .navbar {
    .navbar__menu {
      width: 100%;
      ul {
        flex-direction: row;
        justify-content: center !important;
        align-items: center;
        letter-spacing: 0;
        width: 0;
        li {
          font-size: 1rem;
          align-items: center;
          letter-spacing: 0;
          position: relative;
          right: 20px;
        }
      }
    }
  }
  .navbar__container__scrolled {
    padding-top: 20px;
    transition: all 0.5s ease;
    .navbar__menu {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      transition: all 0.5s ease-in-out;
    }
  }
}
