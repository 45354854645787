.footer {
  background-color: #333;
  color: #fff;
  padding: 4.8rem 0;
  text-align: center;
  width: 100%;
  .footer__upArrow {
    width: 2%;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .footer__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    width: 25%;
    margin: 0 auto;
    padding: 1rem 0;
    border-bottom: 1px solid gray;
    .icon {
      width: 3rem;
      height: 3rem;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: translateY(-5px);
      }
      img {
        width: 40px;
        height: 35px;
        object-fit: contain;
      }
    }
  }
}
