.project {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  max-width: 49%;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  transition: all 0.3s ease-out;
  margin-bottom: 20px;
  cursor: pointer;
  .project__image {
    width: 100%;
    height: 33%;
    border-radius: 12px 12px 0 0;
    img {
      width: 100%;
      height: 100%;
      opacity: 0.8;
      object-fit: cover;
      border-radius: 12px 12px 0 0;
      transition: all 0.3s ease-out;
      margin-top: 0;
    }
  }

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.5rem 0;
    text-transform: uppercase;
  }

  .project__subtitle {
    p {
      font-size: 1rem;
      font-weight: 400;
      padding: 5px;
      // margin: 0.5rem 0;
    }
  }
  .project__info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0.5rem 0;
    .project__technologies {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 50%;
      font-family: 'Tahoma';
      margin: 0.5rem;
      .header {
        font-family: 'Tahoma';
        color: #fff;
      }
      .subtitle {
        font-family: 'Tahoma';
        color: #fff;
      }
    }
    .project__links {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 50%;
      margin: 0.5rem 0;
      .header {
        color: #fff;
      }
      .links {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 60%;
        a {
          display: flex;
          flex-direction: column;
          text-decoration: none;
          color: #000;
          font-size: 1.5rem;
          font-weight: 500;

          i {
            margin: 0 0.5rem;
            img {
              width: 75%;
            }
          }
        }
      }
    }
  }

  &:hover {
    transform: scale(1.05);
    .project__image {
      img {
        opacity: 1;
      }
    }
  }
}

h1,
h3,
p {
  font-family: 'Tahoma';
  color: #d3d1d1;
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .project {
    max-width: 100%;
    width: 100%;
    height: 50%;
    margin-bottom: 20px;
    .project__image {
      width: 100%;
      height: 100%;
      border-radius: 12px 12px 0 0;
      object-fit: cover;
      img {
        width: 100%;
        height: 100%;
        border-radius: 12px 12px 0 0;
      }
    }
    .project__text {
      width: 100%;
      height: 100%;
      padding: 1rem;
      h1 {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0.5rem 0;
      }
    }

    .project__subtitle {
      p {
        font-size: 1rem;
        font-weight: 400;
        margin: 0.1rem 0;
      }
    }
    .project__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0.5rem 0;
      .project__technologies {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin: 0.5rem;
        .header {
          color: #fff;
        }
        .subtitle {
          color: #fff;
          font-size: 14px;
        }
      }
      .project__links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        margin: 0.5rem 0;
        .header {
          color: #fff;
          font-size: 18px;
        }
        .links {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 100%;
          gap: 20px;
          a {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            color: #000;
            font-size: 1.5rem;
            font-weight: 500;
            gap: 20px;
            i {
              // margin: 0 0.5rem;
              width: 100%;
              img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 1023px) {
  .project {
    max-width: 48%;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;
    .project__image {
      width: 100%;
      height: 100%;
      border-radius: 12px 12px 0 0;
      object-fit: cover;
      img {
        width: 100%;
        height: 100%;
        opacity: 0.5;
        border-radius: 12px 12px 0 0;
      }
    }
    .project__text {
      width: 100%;
      height: 100%;
      padding: 1rem;
      h1 {
        font-size: 1.5rem;
        font-weight: 500;
        margin: 0.5rem 0;
      }
    }

    .project__subtitle {
      p {
        font-size: 1rem;
        font-weight: 400;

        margin: 0.5rem 0;
      }
    }
    .project__info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 0.5rem 0;
      .project__technologies {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 50%;
        margin: 0.5rem;
        .header {
          color: #fff;
          font-size: 16px;
        }
        .subtitle {
          color: #fff;
          font-size: 14px;
          margin-top: 0 !important;
          margin-bottom: 0 !important;
        }
      }
      .project__links {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 50%;
        margin: 0.5rem 0;
        .header {
          color: #fff;
          font-size: 16px;
        }
        .links {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          padding-right: 10px;
          a {
            display: flex;
            flex-direction: column;
            text-decoration: none;
            color: #000;
            font-size: 1.5rem;
            font-weight: 500;
            gap: 20px;
            i {
              width: 100%;
              img {
                width: 80%;
              }
            }
          }
        }
      }
    }
  }
}
