.about {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 120px 20px 120px 20px;
  box-sizing: border-box;
  gap: 40px;
  margin: 60px 0 0 0;
  .about__image {
    max-width: 25%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
      transition: all 5s ease-in-out;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
  .about__title {
    width: 75%;
    display: flex;
    flex-direction: column;
    text-align: start;
    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: 0.2rem;
      color: #fefefe;
      margin-top: 0 !important;
      margin-bottom: 10px !important;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
      color: #fefefe;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    p {
      font-size: 1.1rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
      color: #fefefe;
      width: 80%;
      .highlight {
        font-size: 1.1rem;
        letter-spacing: 0.2rem;
        font-weight: 400;
        color: #c07a6e;
      }
    }
    .resume {
      color: #fefefe;
      text-decoration: none;
      font-size: 1.2rem;
      width: 30%;
      transition: all 0.3s ease-in-out;
      padding: 15px 40px;
      border: 1px solid #fefefe;
      border-radius: 5px;
      text-align: center;
      text-decoration: none;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      background-color: transparent;
      &:hover {
        background-color: #fefefe;
        color: #000;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .about {
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    .about__image {
      max-width: 50%;
    }
    .about__title {
      width: 100%;
      .resume {
        width: 100%;
      }
      .subtitle {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 1024px) {
  .about {
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    .about__image {
      max-width: 50%;
    }
    .about__title {
      width: 100%;
      .resume {
        width: 100%;
        margin: 0 auto;
      }
      .subtitle {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1025px) and (max-width: 1280px) {
  .about {
    gap: 20px;
    padding: 20px 20px 0 20px;
    .about__image {
      max-width: 30%;
    }
    .about__title {
      width: 100%;
      .resume {
        width: 70%;
      }
    }
  }
}
