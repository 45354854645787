.projects {
  width: 100%;
  background-color: #262525;
  padding: 4rem 0;
  .projects__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 70%;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    text-align: center;
    .header {
      font-size: 2.5rem;
      font-weight: 600;
      letter-spacing: 0.2rem;
      color: #fefefe;
      margin-top: 0 !important;
      margin-bottom: 40px;
      text-transform: uppercase;
    }
    .project__items {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 100%;
      margin: 0 auto;
      padding: 0 1rem;
      gap: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .projects {
    padding: 2rem 0;
  }
  .header {
    margin-bottom: 10px !important;
  }
  .projects__wrapper {
    max-width: 100% !important;
    width: 100% !important;
  }
}
