.App {
  text-align: center;
  width: 70%;
  margin: 0 auto;
  font-family: 'Tahoma';
  src: url('./tahoma.ttf') format('ttf');
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .App {
    width: 100%;
  }
}
h1,
h3,
p {
  font-family: 'Tahoma';
  color: #d3d1d1;
}
