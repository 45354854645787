body {
  margin: 0;
  box-sizing: border-box;
  background-color: rgb(48, 41, 41);
}

* {
  box-sizing: border-box;
}
h1,
h3,
p {
  font-family: 'Tahoma';
  color: #d3d1d1;
}
